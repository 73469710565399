import React from 'react';
import './About.scss'
import aboutImg from '../../assets/about-img.jpg'
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

const About = () => {

  const {t} = useTranslation();

  return (
    <section className='about-section'>
      <div className='about-section__container'>
        <div className='about-section__about-block'>
          <Fade>
            <div className='about-section__left-block'>
              <img src={aboutImg} alt='' className='about-section__img'></img>
            </div>
          </Fade>
          <Fade right>
            <div className='about-section__right-block'>
              <h1 className='about-section__company-name'>{t('About.title')}</h1>
              <span className='about-section__second-desc'>{t('About.firstDesc')}</span>
              <hr className="about-section__hr-desc"/>
              <span className='about-section__first-desc'>{t('About.secondDesc')}</span>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default About;