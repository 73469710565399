import React from 'react';
import './SatbayevDescription.scss'
import { useTranslation } from 'react-i18next';

const SatbayevDescription = () => {

  const { t } = useTranslation();

  return (
    <div className='satbayev'>
      <span className='satbayev__txt'>
        <span className='satbayev__txt-bold'>{t('Organizers.satbayevTitle')}</span>
          {t('Organizers.satbayevDesc')}
      </span>
    </div>
  );
};

export default SatbayevDescription;