import React, { useState, useEffect } from 'react';
import './ScrollButton.scss'
import { Fade } from 'react-reveal';
import arrow from '../../assets/arrowhead-up.png'
const ScrollButton = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fade right>
      <div className="scroll-button" style={{ display: showButton ? 'block' : 'none' }}>
        <button onClick={scrollToTop}>
          <img src={arrow} alt='' className='arrow'></img>
        </button>
      </div>
    </Fade>
  );
};

export default ScrollButton;
