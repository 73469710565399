import kyrgRus1 from '../../../assets/kyrg-rus1.jpg'
import kyrgRus2 from '../../../assets/kyrg-rus2.jpg'
import kyrgRus3 from '../../../assets/kyrg-rus3.jpg'

const kyrgyzRusData = [
  {
    school: 'Кыргызско-Русский Славянский университет имени Б.Н. Ельцина',
    city: 'Бишкек',
    country: 'Киргизия',
    participants: [
      'Ким Елена Николаевна',
      'Бактыгулова Айдай Бактыгуловна',
      'Токбергенова Карина Маратовн',
      'Умарова Мадина Розумахаматовна',
      'Кариев Бейсен Сергеевич'
    ],
    images: [kyrgRus1]
  },
  {
    school: 'Кыргызско-Русский Славянский университет имени Б.Н. Ельцина',
    city: 'Бишкек',
    country: 'Киргизия',
    participants: [
      'Баяхунова Залина',
      'Бондаренко Римма',
      'Войнова Дарья',
      'Ращупкина Таисия',
      'Талантбек кызы Мария',
      'Цветкова Дарья',
      'Кариев Бейсен Сергеевич'
    ],
    images: [kyrgRus2]
  },
  {
    school: 'Кыргызско-Русский Славянский университет имени Б.Н. Ельцина',
    city: 'Бишкек',
    country: 'Киргизия',
    participants: [
      'Балтовская Анастасия Александровна',
      'Марат кызы Бермет',
      'Русланова Адэма Руслановна',
      'Жумаева Виктория Рыспековна',
      'Кариев Бейсен Сергеевич',
      'Руденко Юлия Владимировна'
    ],
    images: [kyrgRus3]
  },
  
];

export default kyrgyzRusData;