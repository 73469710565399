import ptam1 from '../../../assets/work-examples/ptam1.jpg'


const ptamData = [
  {
    school: 'ООО «ПТАМ ВИССАРИОНОВА»',
    city: 'Москва',
    country: 'Российская Федерация',
    
    participants: [
      'Сааков Леон Леонович',
      'Загваздин Илья Максимович',
      'Виссарионов Юрий Геннадьевич'
    ],
    images: [ptam1]
  },
 
];

export default ptamData;