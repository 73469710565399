import gumilev1 from '../../../assets/work-examples/gumilev1.jpg'
import gumilev2 from '../../../assets/work-examples/gumilev2.jpg'
import gumilev3 from '../../../assets/work-examples/gumilev3.jpg'
import gumilev4 from '../../../assets/work-examples/gumilev4.jpg'
import gumilev5 from '../../../assets/work-examples/gumilev5.jpg'
import gumilev6 from '../../../assets/work-examples/gumilev6.jpg'
import gumilev7 from '../../../assets/work-examples/gumilev7.jpg'
import gumilev8 from '../../../assets/work-examples/gumilev8.jpg'
import gumilev9 from '../../../assets/work-examples/gumilev9.jpg'


const gumilevUnivData = [
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Куанышпаева Софья Омирбековна',
      'Лисин Валерий Сергеевич',
      'Цуцурина Марина Николаевна',
      'Сулейменова Амина Еркебуланкызы',
      'Енсебаев Тимур Маратович'
    ],
    images: [gumilev1]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Тлекенов Алмаз Амангельдиевич',
      'Майшулан Аружан Рамилькызы',
      'Темiржанова Елжан Бауыржанкызы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev2]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Әліпбай Серікболсын Сәбитбекұлы',
      'Ташенова Кымбат Ергаликызы',
      'Буркулов Жан Бегалиұлы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev3]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Мукушева Диана Маратовна',
      'Тельман Карина',
      'Адилетов Махамбет Адилетулы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev4]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Умарова Фатима Мухитқызы',
      'Марк Ислам Болатұлы',
      'Жөкен Аяжан Асанқызы',
      'Илясханов Серік Әлімханұлы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev5]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Абжанова Асем Улановна',
      'Бибекова Азиза Кабышевна',
      'Жантас Жансерик Ерсаянулы',
      'Шаймерденова Гулфайруз Сахикызы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev6]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Абжанова Асем Улановна',
      'Бибекова Азиза Кабышевна',
      'Жантас Жансерик Ерсаянулы',
      'Шаймерденова Гулфайруз Сахикызы',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev7]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
      'Хавдалхамит Ережеп',
      'Көшкінбек Дарина',
      'Альбусынова Сымбат Думановна',
      'Талғатқызы Дильназ',
      'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev8]
  },
  {
    school: 'Евразийский национальный университет им. Л.Н. Гумилева',
    city: 'Астана',
    country: 'Республика Казахстан',
    participants: [
    'Нурмаганбетов Марлен Талгатулы',
    'Айдарбай Меруерт',
    'Қайратқызы Нұрайым',
    'Секерова Несибели',
    'Бутабекова Аида Сарсембаевна'
    ],
    images: [gumilev9]
  },

];

export default gumilevUnivData;