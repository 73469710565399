import React from 'react';
import './Organizers.scss'
import logo1 from '../../assets/logo1.png'
import logo2 from '../../assets/logo2.jpg'
import logo3 from '../../assets/logo3.jpg'
import Fade from 'react-reveal/Fade';
import NomadDescription from './ui/NomadDescription/NomadDescription';
import MaamDescription from './ui/MaamDescription/MaamDescription';
import SatbayevDescription from './ui/SUDescription/SatbayevDescription';
import { useTranslation } from 'react-i18next';

const Organizers = ({ openModal }) => {

  const {t} = useTranslation();

  const organizersData = [
    {
      title: t('Organizers.nomad'),
      logo: logo1,
      description: 'nomad'
    },
    {
      title: t('Organizers.iaam'),
      logo: logo2,
      description: 'iamm'
    },
    {
      title: t('Organizers.satbayev'),
      logo: logo3,
      description: 'satbayev'
    }
  ];

  return (
    <section className='organizers-section'>
      <div className='organizers-section__container'>
        <h1 className='organizers-section__main-title'>{t('Organizers.title')}</h1>

        <div className='organizers-section__items-block'>
          {organizersData.map((organizer, index) => (
            <Fade bottom>
              <div key={index} className='organizers-section__item' onClick={() => openModal(organizer.description, organizer.logo)}>
                <div className='organizers-section__logo-block'>
                  <img className='organizers-section__logo' src={organizer.logo} alt="" />
                </div>
                <div className='organizers-section__item-desc'>
                  <span className='organizers-section__txt'>{organizer.title}</span>
                  <span className='organizers-section__more'>{t('Organizers.more')}</span>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Organizers;