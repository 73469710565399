import React from 'react';
import './Contacts.scss'
import suLogo from '../../assets/logo3.png'
import nomadLogo from '../../assets/logo1.png'
import Slide from 'react-reveal/Slide';
import { useTranslation } from 'react-i18next';

const Contacts = () => {

  const {t} = useTranslation();


  return (
    <section className='contacts-section'>
      <div className='contacts-section__container'>
        <h1 className='contacts-section__main-title'>{t('Contacts.title')}</h1>
        <div className='contacts-section__contact-box'>

          <Slide left>
            <div className='contacts-section__contact-item'>
              <div className='contacts-section__logo-box'>
                <img src={suLogo} alt='' className='contacts-section__logo'/>
              </div>
              <div className='contacts-section__txt-box'>
                <div className='contacts-section__txt-item'>
                  <h3>Ходжиков Антон Валерьевич</h3>
                  <span className='contacts-section__position'>{t('Contacts.position1')}</span>
                  <span className='contacts-section__position'>{t('Contacts.place1')}</span>
                 
                  <div>
                    <span>{t('Contacts.mobile')} </span>
                    <a href='tel:+77027605786' className='contacts-section__link'>+7 702 760 5786</a>
                  </div>
                  <div>
                    <span>{t('Contacts.email')} </span>
                    <a href='mailto:a.khojikov@satbayev.university'
                       className='contacts-section__link'>a.khojikov@satbayev.university</a>
                  </div>
                </div>
                <div className='contacts-section__txt-item'>
                  <h3>Әбілев Дінмұхамед Нұрланұлы</h3>
                  <span className='contacts-section__position'>{t('Contacts.position1')}</span>
                  <span className='contacts-section__position'>{t('Contacts.place1')}</span>
                  
                  <div>
                    <span>{t('Contacts.mobile')} </span>
                    <a href='tel:+77475389426' className='contacts-section__link'>+7 747 538 9426</a>
                  </div>
                  <div>
                    <span>{t('Contacts.email')} </span>
                    <a href='mailto:d.abilev@satbayev.university'
                       className='contacts-section__link'>d.abilev@satbayev.university</a>
                  </div>
                </div>
              </div>
            
            </div>
          </Slide>
          <Slide right>
            <div className='contacts-section__contact-item'>
              <div className='contacts-section__logo-box'>
                <img src={nomadLogo} alt='' className='contacts-section__logo'/>
              </div>
              <div className='contacts-section__txt-box'>
                <div className='contacts-section__txt-item'>
                  <h3>Бейсенбек Құттыбекұлы</h3>
                  <span className='contacts-section__position'>{t('Contacts.position2')}</span>
                  <div>
                    <span>{t('Contacts.email')} </span>
                    <a href='mailto:turanorda.kz@mail.ru'
                       className='contacts-section__link'>turanorda.kz@mail.ru</a>
                  </div>
                </div>
                <div className='contacts-section__txt-item'>
                  <h3>Салахиев Ильдар Рафхатович</h3>
                  <span className='contacts-section__position'>{t('Contacts.position3')}</span>
                  <div>
                    <span>{t('Contacts.mobile')} </span>
                    <a href='tel:+77072991270' className='contacts-section__link'>+7 707 299 12 70</a>
                  </div>
                  <div>
                    <span>{t('Contacts.email')} </span>
                    <a href='mailto:turanorda.kz@mail.ru'
                       className='contacts-section__link'>turanorda.kz@mail.ru</a>
                  </div>
                </div>
              </div>
            </div>


          </Slide>
        </div>
      </div>
    </section>
  );
};

export default Contacts;