import karaganda1 from '../../../assets/work-examples/karaganda1.jpg'
import karaganda2 from '../../../assets/work-examples/karaganda2.jpg'

const karagandaUnivData = [
  {
    school: 'НАО «Карагандинский технический университет имени Абылкаса Сагинова»',
    city: 'Караганда',
    country: 'Республика Казахстан',
    participants: [
      'Сексембаева Амина Бакытжановна',
      'Журавкова Алина Николаевна'
    ],
    images: [karaganda1]
  },
  {
    school: 'НАО «Карагандинский технический университет имени Абылкаса Сагинова»',
    city: 'Караганда',
    country: 'Республика Казахстан',
    participants: [
      'Храмкова Арина Ивановна',
      'Каналбек Аружан Ғалымжанқызы',
      'Рахатова Даяна Рабхатқызы',
      'Борисевич Юлия Адамовна'
    ],
    images: [karaganda2]
  },
  
];

export default karagandaUnivData;