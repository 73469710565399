// import kazgasa1 from '../../../assets/work-examples/kazgasa1.jpg'
// import kazgasa2 from '../../../assets/work-examples/kazgasa2.jpg'
// import kazgasa3 from '../../../assets/work-examples/kazgasa3.jpg'
import bureau1 from '../../../assets/work-examples/bureau1.jpg'

const archBureaData = [
  {
    school: 'Архитектурное бюро «BA concept»',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Касенеев Саламат Еркинович',
      'Ергашев Рауфжон Абдурашидович',
      'Осимбаева Айнур Бахытжановна'
    ],
    
    images: [bureau1]
  },
];

export default archBureaData;