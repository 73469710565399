import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Organizers from "./components/Organizers/Organizers";
import Contest from "./components/Сontest/Contest";
import Conditions from "./components/Conditions/Conditions";
import Task from "./components/Task/Task";
import Applications from "./components/Applications/Applications";
import Pass from "./components/Pass/Pass";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";
import { Element } from 'react-scroll';
import { Suspense, useState, useEffect } from 'react';
import News from "./components/News/News";
import React from 'react';
import Modal from "./components/Organizers/features/Modal";
import './App.scss'
import ScrollButton from "./shared/ScrollButton/ScrollButton";
import Works from "./components/Works/Works";
import WorkPage from "./components/WorkPage/WorkPage";  // Импортируйте ваш компонент WorkPage
import { Routes, Route } from "react-router-dom";

function App() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setModalDescription] = useState()
  const [modalImg, setModalImage] = useState()

  // useEffect(() => {
  //   const scrollPosition = localStorage.getItem('homeScroll')
  //   console.log(scrollPosition)
  //   if (scrollPosition) {
  //     console.log('scroll est')
  //     window.scrollTo(0, scrollPosition);
   
  //   }
  // }, []);

  const openModal = (description, logo) => {
    setModalDescription(description); // Устанавливаем заголовок модального окна
    setModalImage(logo); // Устанавливаем изображение модального окна
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Element name="hero">
                <Hero />
              </Element>
              <Element name="about">
                <About />
              </Element>
              <Element name="organizers">
                <Organizers openModal={openModal} />
              </Element>
              <Element name="contest">
                <Contest />
              </Element>
              <Element name="conditions">
                <Conditions />
              </Element>
              <Element name="task">
                <Task />
              </Element>
              <Element name="applications">
                <Applications />
              </Element>
              <Element name="pass">
                <Pass />
              </Element>
              <Element name="news">
                <News />
              </Element>
              <Element name="works">
                <Works />
              </Element>
              <Element name="contacts">
                <Contacts />
              </Element>
              <Modal isOpen={isModalOpen} onClose={closeModal} description={description} logo={modalImg}></Modal>
              <ScrollButton />
              <Footer />
            </>
          }
        />
        <Route path="/example" element={<WorkPage />} /> {/* Добавьте новый маршрут для /example */}
      </Routes>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback='...loading'>
      <App />
    </Suspense>
  )
};
