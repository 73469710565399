import kyrgyz1 from '../../../assets/work-examples/kyrgyz1.jpg'
import kyrgyz2 from '../../../assets/work-examples/kyrgyz2.jpg'

const kyrgyzData = [
  {
    school: 'Кыргызский Государственный технический университет им. Исхака Раззакова',
    city: 'Бишкек',
    country: 'Киргизия',
    participants: [
      'Сатенов Айбек Эмирович',
      'Акималиев Ислам Анарбекович'
    ],
    images: [kyrgyz1]
  },
  {
    school: 'Кыргызский Государственный технический университет им. Исхака Раззакова',
    city: 'Бишкек',
    country: 'Киргизия',
    participants: [
      'Асылбеков Керим Робертович',
      'Нарынбеков Кылымбек Нарынбекович',
      'Турдубеков Аман Кубанычбекович',
      'Искендеров Улан Жолдошбекович'
    ],
    images: [kyrgyz2]
  },
  
];

export default kyrgyzData;