import exmpl1 from '../../../assets/work-examples/ex1.jpg';
import exmpl2 from '../../../assets/work-examples/ex2.jpg';
import su2 from '../../../assets/work-examples/su2.jpg';
import su3 from '../../../assets/work-examples/su3.jpg';
import su4 from '../../../assets/work-examples/su4.jpg';
import su5 from '../../../assets/work-examples/su5.jpg';
import su6 from '../../../assets/work-examples/su6.jpg';
import su7 from '../../../assets/work-examples/su7.jpg';
import su81 from '../../../assets/work-examples/su8-1.jpg';
import su82 from '../../../assets/work-examples/su8-2.jpg';
import su9 from '../../../assets/work-examples/su9.jpg'
import su10 from '../../../assets/work-examples/su10.png'

const satbayevUniversityData = [
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: ['Поминов Вадим Константинович', 'Меламедова Софья Кирилловна', 'Богза Виктория Андреевна',
     'Коновалова Екатерина Михайловна', 'Лукин Анатолий Александрович', 'Кайсаров Ернар Альжанович', 'Яскевич Владимир Владимирович'],
    images: [exmpl1, exmpl2]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Адамжанова Адель Ескендировна',
      'Маржохов Темирхан Альбердович',
      'Мустафина Айнур Акимбековна',
      'Аманкосова Айым Бауыржанкызы',
      'Сактапберген Махамбет Исатайулы',
      'Капанов Ануар Адамович'
    ],
    images: [su2]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants : [
      'Карчиев Жасурбек Эркинбаевич',
      'Мұрат Мәди Бауыржанұлы',
      'Имэл Амира Ришаткызы',
      'Слямхан Диас Дәуренұлы',
      'Мусин Болат Башарович'
    ],
    images: [su3]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants : [
      'Ершат Ботагоз',
      'Хамей Ғалымжан',
      'Цой Эдуард Иннокентьевич'
    ],
    images: [su4]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Иванова Анастасия Викторовна',
      'Джумадилова Дания Данияровна',
      'Камалова Гульнара Мамырбековна'
    ],
    images: [su5]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Ким Андрей Евгеньевич',
      'Бойцова Людмила Сергеевна',
      'Айсаров Алишер Русланович',
      'Садыков Масимжан Сыдыкжанович',
      'Асамбекова Айсулу Сейтжановна',
      'Халиулина Камиля Ержанқызы',
      'Капанов Ануар Адамович'
    ],
    
    images: [su6]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Минайдар Жәния Болатқызы',
      'Мауленова Гульнар Джупарбековна'
    ],
    images: [su7]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Дниева Элина Альмирдановна',
      'Сарбаева Лейла Бахыткызы',
      'Мауленова Гульнара Джупарбековна'
    ],
    images: [su81, su82]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Сулейменова Алтын',
      'Мауленова Гульнара Джупарбековна'
    ],
    images: [su9]
  },
  {
    school: 'Казахский национальный исследовательский технический университет имени К. И. Сатпаева',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Чекмарев Андрей Александрович',
      'Мұрат Томирис Мукарамқызы',
      'Баймуратова Линара Тохировна',
      'Габбасова Анеля Сагатовна',
      'Cyprian Joseph'
    ],
    images: [su10]
  },
];

export default satbayevUniversityData;
