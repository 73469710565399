import React, { useState } from 'react';
import './WorkHeader.scss'
import LanguageChanger from '../Header/features/LanguageChanger';
import { useNavigate } from 'react-router-dom';
import headerLogo1 from '../../assets/h-logo1.png'
import headerLogo2 from '../../assets/h-logo2.jpg'
import headerLogo3 from '../../assets/h-logo3.jpg'
// import { useTranslation } from 'react-i18next';

const WorkHeader = () => {

  const navigate = useNavigate();

  // const {t} = useTranslation();
  const handleBackClick = () => {
    navigate(-1)
  }

  return (
    <header className="header">
      <div className="header__container">

        <div className="header__logo-block">
          <img className="header__logo" src={headerLogo1} alt=''></img>
          <img className="header__logo" src={headerLogo3} alt=''></img>
          <img className="header__logo" src={headerLogo2} alt=''></img>
        </div>

        <div className='work-header__container'>
          <button className='back-button' onClick={handleBackClick}>Назад</button>
          <LanguageChanger/>

          
        </div>
      </div>
     
    </header>
  );
};

export default WorkHeader;
