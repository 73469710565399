import tash1 from '../../../assets/work-examples/tash1.jpg'
import tash2 from '../../../assets/work-examples/tash2.jpg'
import tash3 from '../../../assets/work-examples/tash3.jpg'

const tashkentData = [
  {
    school: 'Ташкентский архитектурно строительный университет',
    city: 'Ташкент',
    country: 'Узбекистан',
    participants: [
      'Кульмухамедов Ахмад Мардон угли',
      'Розыбакиева Ситора Чингизовна',
      'Шомахкамов Шомухаммад Шоаброр угли',
      'Данилов Владислав Валентинович'
    ],
    images: [tash1]
  },
  {
    school: 'Ташкентский архитектурно строительный университет',
    city: 'Ташкент',
    country: 'Узбекистан',
    participants: [
      'Соипова Гульноза Илхомжон кизи',
      'Анваржонова Сарвара Бахтиёр кизи',
      'Иргашева Элеонора Ильхомовна',
      'Гончаров Константин Эдуардович',
      'Бегматова Дилдор Акрамовна'
    ],
    images: [tash2]
  },
  {
    school: 'Ташкентский архитектурно строительный университет',
    city: 'Ташкент',
    country: 'Узбекистан',
    participants: [
      'Тохирова Малика Шерзод кизи',
      'Омонбоева Дурдона Жамшид кизи',
      'Носирова Фарангиз Абдусамат кизи',
      'Ходжаева Мадина Махмуджоновна'
    ],
    images: [tash3]
  },
];

export default tashkentData;