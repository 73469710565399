import React from 'react';
import './Applications.scss'
import booklet from '../../assets/booklet1.png'
import plan from '../../assets/plan.png'
import txttemp from '../../assets/txttempl.png'
import template from '../../assets/template.png'
import locIcon from '../../assets/loc.png';
import letter from '../../assets/letter.png'
import ifIcon from '../../assets/ifif.png'
import Flip from 'react-reveal/Flip';
import HeadShake from 'react-reveal/HeadShake';

import bookletRu from './files/Буклет Международного Туристического комплекса.pdf';
import basis from './files/Основа для генерального плана.zip';
import templateRu from './files/Пояснительная записка.docx';
import form from './files/Заявка.docx'

//5 
import letterRu from './files/inf-5/ИнфПисьмо-Конкурс ММЦ_ВсемирныйЦентрНомадов2023_рус.pdf'
import letterKz from './files/inf-5/АқпаратХат-Конкурс ХЖО_ДүниежүзілікКөшпенділерОрталығы2023_қаз.pdf'
import letterEn from './files/inf-5/InformLetter-Сompetition IYC_World of NomadsCenter2023_eng.pdf'

//6
import condRu from './files/cond-6/Условия конкурса ММЦ_Всемир.Центр Номадов 2023_рус.pdf'
import condKz from './files/cond-6/ХЖО конкурс шарттары_ДүниежүзілікКөшпенділерОрталығы2023_қаз.pdf'
import condEn from './files/cond-6/Terms of the competition IYC_World of NomadsCenter2023_eng.pdf'

import { useTranslation } from 'react-i18next';

const Applications = () => {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  function openGoogleMapsLink() {
    const link = 'https://maps.app.goo.gl/9y6r8tzwUHo85XoB7';
    window.open(link, '_blank');
  }

  const handleBookletClick = () => {
    const link = document.createElement('a');
    link.href = bookletRu; // URL to your PDF file
    link.download = 'Буклет Международного Туристического комплекса.pdf'; // Name of the file when downloaded
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleBasisClick = () => {
    const link = document.createElement('a');
    link.href = basis; // URL to your PDF file
    link.download = 'Основа для генерального плана.zip'; // Name of the file when downloaded
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  const handleTemplateClick = () => {
    const link = document.createElement('a');
    link.href = templateRu; // URL to your PDF file
    link.download = 'Пояснительная записка.docx'; // Name of the file when downloaded
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  const handleFormClick = () => {
    const link = document.createElement('a');
    link.href = form; // URL to your PDF file
    link.download = 'Заявка.docx'; // Name of the file when downloaded
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  // 5 
  const handleLetterClick = () => {
    const link = document.createElement('a');
    const currentLanguage = i18n.language;

    let formFilePath;
    let nameDownload = '';

    if (currentLanguage === 'ru') {
      nameDownload = 'ИнфПисьмо-Конкурс ММЦ_ВсемирныйЦентрНомадов2023_рус.pdf';
      formFilePath = letterRu;
    } else if (currentLanguage === 'en') {
      nameDownload = 'InformLetter-Сompetition IYC_World of NomadsCenter2023_eng.pdf';
      formFilePath = letterEn;
    } else if (currentLanguage === 'kz') {
      nameDownload = 'АқпаратХат-Конкурс ХЖО_ДүниежүзілікКөшпенділерОрталығы2023_қаз.pdf';
      formFilePath = letterKz;
    }

    link.href = formFilePath; 
    link.download = nameDownload;

    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  // 6 

  const handleConditionsClick = () => {
    const link = document.createElement('a');
    const currentLanguage = i18n.language;

    let formFilePath;
    let nameDownload = '';

    if (currentLanguage === 'ru') {
      nameDownload = 'Условия конкурса ММЦ_Всемир.Центр Номадов 2023_рус.pdf';
      formFilePath = condRu;
    } else if (currentLanguage === 'en') {
      nameDownload = 'Terms of the competition IYC_World of NomadsCenter2023_eng.pdf';
      formFilePath = condEn;
    } else if (currentLanguage === 'kz') {
      nameDownload = 'ХЖО конкурс шарттары_ДүниежүзілікКөшпенділерОрталығы2023_қаз.pdf';
      formFilePath = condKz;
    }

    link.href = formFilePath; 
    link.download = nameDownload;

    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }


  return (
    <section className="applications-section">
      <div className='applications-section__container'>
        <h1 className='applications-section__main-title'>{t('Application.title')}</h1>
        <span className='applications-section__subtitle'>{t('Application.subtitle')}</span>
        <div className='applications-section__appl-block'>

          <Flip top>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={booklet} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application1')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applOne')}</span>

                <span className='applications-section__txt-link' onClick={handleBookletClick} >{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>

          <Flip bottom>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={plan} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application2')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applTwo')}</span>

                <span className='applications-section__txt-link' onClick={handleBasisClick}>{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>
          <Flip top>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={txttemp} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application3')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applThree')}</span>
                <span className='applications-section__txt-link' onClick={handleTemplateClick}>{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>
          <Flip bottom>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={template} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application4')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applFour')} </span>
                <span className='applications-section__txt-link' onClick={handleFormClick}>{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>
          <Flip top>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={letter} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application5')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applFive')}</span>
                <span className='applications-section__txt-link' onClick={handleLetterClick}>{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>
          <Flip bottom>
            <div className='applications-section__appl-item'>
              <div className='applications-section__icon-block'>
                <img src={ifIcon} alt='' className='applications-section__icon'></img>
              </div> 
              <div className='applications-section__txt-block'>
                <h4 className='applications-section__txt-title'>{t('Application.application6')}</h4>
                <span className='applications-section__txt-subtitle'>{t('Application.applSix')}</span>
                <span className='applications-section__txt-link' onClick={handleConditionsClick}>{t('Application.downloadBtn')}</span>
              </div>
            </div>
          </Flip>
        </div>

        <div className='applications-section__btn-block'>
          <HeadShake>
            <button className='applications-section__loc-btn' onClick={openGoogleMapsLink}>
              <img className="applications-section__loc-img" src={locIcon} alt=''/>
              <span className="applications-section__loc-txt">{t('Application.location')}</span>
            </button>
          </HeadShake>
        </div>
      </div>
    </section>
  );
};

export default Applications;