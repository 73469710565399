import React from 'react';
import './Pass.scss'
import Pulse from 'react-reveal/Pulse';
import Tada from 'react-reveal/Tada';
import HeadShake from 'react-reveal/HeadShake';
import { useTranslation } from 'react-i18next';

const Pass = () => {

  const {t} = useTranslation();

  return (
    <section className='pass-section'>
      <div className='pass-section__container'>
        <HeadShake>
          <h1 className='pass-section__main-title'>{t('Pass.title')}</h1>
        </HeadShake>
        <div className='pass-section__subtitle-block'>
          <span>{t('Pass.date1')}</span>
          <span>{t('Pass.date2')}</span>
        </div>
        <span className='pass-section__subtitle'>{t('Pass.subtitle')}</span>

        <div className='pass-section__desc-box'>

          <Pulse>
            <div className='pass-section__desc-item'>
              <div className='pass-section__desc-header'>
                {t('Pass.conditions')}
              </div>
              <div className='pass-section__txt-box'>
                {t('Pass.conditionsSpanOne')}
                <ul className='pass-section__txt-list'>
                  <li>{t('Pass.conditionsList1')}</li>
                  <li>{t('Pass.conditionsList2')}</li>
                  <li>{t('Pass.conditionsList3')}</li>
                  <li>{t('Pass.conditionsList4')}</li>
                  <li>{t('Pass.conditionsList5')}</li>
                  <li>{t('Pass.conditionsList6')}</li>
                </ul>
                {t('Pass.conditionsSpanTwo')}
              </div>
            </div>
          </Pulse>
          
          <Pulse>
            <div className='pass-section__desc-item'>
              <div className='pass-section__desc-header'>
                {t('Pass.form')}
              </div>
              <div className='pass-section__txt-box'>
                {t('Pass.formSpanOne')} 
                <ul className='pass-section__txt-list'>
                  <li>{t('Pass.formList1')}</li>
                  <li>{t('Pass.formList2')} </li>
                  <li>{t('Pass.formList3')} </li>
                  <li>{t('Pass.formList4')}</li>
                </ul>
                {t('Pass.formSpanTwo')} 
              </div>
            </div>
          </Pulse>
          
          <Pulse>
            <div className='pass-section__desc-item'>
              <div className='pass-section__desc-header'>
                {t('Pass.mode')} 
              </div>
              <div className='pass-section__txt-box'>
                {t('Pass.modeItem1')} <br/>
                {t('Pass.modeItem2')}<br/>
                {t('Pass.modeItem3')}<br/>
                {t('Pass.modeItem4')}

              </div>
            </div>
          </Pulse>
          
        </div>

        <div className='pass-section__email-block'>
          <span className='pass-section__email-title'>{t('Pass.email')}</span>
          <Tada>
            <a href='mailto:archkaf@satbayev.university' className='pass-section__email-link'>
              archkaf@satbayev.university
            </a>
          </Tada>
        </div>
      </div>
    </section>
  );
};

export default Pass;