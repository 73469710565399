import kazgasa1 from '../../../assets/work-examples/kazgasa1.jpg'
import kazgasa2 from '../../../assets/work-examples/kazgasa2.jpg'
import kazgasa3 from '../../../assets/work-examples/kazgasa3.jpg'

const kazgasaUniversityData = [
  {
    school: 'Международная образовательная корпорация (КазГАСА)',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Абдырахман Алихан Серікқазыұлы',
      'Кусаинов Аскар Муратович'
    ],
    images: [kazgasa1]
  },
  {
    school: 'Международная образовательная корпорация (КазГАСА)',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Огай Ольга Павловна',
      'Акмахан Баглан Ермекулы',
      'Молдасихов Шалкар Абилхаирулы',
      'Елеубекова Жансая Манасовна',
      'Ким Виктор',
      'Мухамеджанов Аскар Ахметжанович'
    ],
    images: [kazgasa2]
  },
  {
    school: 'Международная образовательная корпорация (КазГАСА)',
    city: 'Алматы',
    country: 'Казахстан',
    participants: [
      'Фрунзе Таир Нурланович'
    ],
    images: [kazgasa3]
  },
];

export default kazgasaUniversityData;