import React from 'react';
import './Hero.scss'
import heroImg from '../../assets/hero-img.jpg'
import locIcon from '../../assets/loc.png';
import ScrollIndicator from './features/ScrollIndicator';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const {t} = useTranslation();

  return (
    <section className="hero-section">
      {/* <ScrollIndicator/> */}
      <div className="hero-section__container">
        <Pulse>
          <h1 className='hero-section__main-title'>
            {t('Hero.title')}
          </h1>
        </Pulse>
        <div className='hero-section__content'>
          <Fade left>
            <div className='hero-section__left-block'>
              <div className='hero-section__desc-block'> 
                <span className='hero-section__date-span'>{t('Hero.date')}</span>
                
                <h2 className='hero-section__company-title'>{t('Hero.secondTitleFirst')} <span className='hero-section__company-title--dot'>{t('Hero.secondTitleAnd')}</span>
                  {t('Hero.secondTitleSecond')}
                </h2>
                <span className='hero-section__invite-txt'>{t('Hero.inviteTxt')}</span>
                <div className="hero-section__loc-block">
                  <img className="hero-section__loc-img" src={locIcon} alt=''/>
                <span className="hero-section__loc-txt">Satbayev University</span>
              </div>
              </div>
            </div>
          </Fade>

          <div className='hero-section__right-block'>
            <img src={heroImg} alt="" className="hero-section__img"/>
          </div>
        </div>
      </div>
    
    </section>
  );
};

export default Hero;