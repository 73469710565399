import React from 'react';
import './Footer.scss'
import logoFooter from '../../assets/logo_footer.png'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className="footer__container">
        <img src={logoFooter} className="footer__logo" alt=''></img>
      </div>
    </footer>
  );
};

export default Footer;