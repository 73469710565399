import React, { useState } from 'react';
import './Header.scss'
import LanguageChanger from './features/LanguageChanger';
import { Link } from 'react-scroll';
import headerLogo1 from '../../assets/h-logo1.png'
import headerLogo2 from '../../assets/h-logo2.jpg'
import headerLogo3 from '../../assets/h-logo3.jpg'
import { useTranslation } from 'react-i18next';

const Header = () => {

  const {t} = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const handleMenuToggle = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  return (
    <header className="header">
      <div className="header__container">

        <div className="header__logo-block">
          <img className="header__logo" src={headerLogo1} alt=''></img>
          <img className="header__logo" src={headerLogo3} alt=''></img>
          <img className="header__logo" src={headerLogo2} alt=''></img>
        </div>

        <div className='header__right-block'>
          <nav className="header__nav-list">
            <Link to="hero" spy={true} smooth={true} duration={500} offset={0} className='header__nav-item'>{t('Header.home')}</Link>
            <Link to="organizers" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.organizers')}</Link>
            <Link to="contest" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.contest')}</Link>
            <Link to="conditions" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.conditions')}</Link>
            <Link to="task" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.task')}</Link>
            <Link to="applications" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.application')}</Link>
            <Link to="pass" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.pass')}</Link>
            <Link to="news" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.news')}</Link>
            <Link to="works" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.works')}</Link>
            <Link to="contacts" spy={true} smooth={true} duration={500} offset={-40} className="header__nav-item">{t('Header.contacts')}</Link>
          </nav>

          <LanguageChanger/>

          <div className='burger-button' >
            <label for="check" className='burger-button__label'>
                <input type="checkbox" id="check" checked={showMenu} onClick={handleMenuToggle}/> 
                <span className='burger-button__burger-line'></span>
                <span className='burger-button__burger-line'></span>
                <span className='burger-button__burger-line'></span>
              </label>
          </div>
        </div>
      </div>
      {showMenu && 
          <div className={`menu-content ${showMenu ? 'show' : ''}`}>
            <div className='menu-content__container'>
              <nav className="menu-content__nav-list">
                <Link to="hero" spy={true} smooth={true} duration={500} offset={0} className='menu-content__nav-item' onClick={handleMenuToggle}>{t('Header.home')}</Link>
                <Link to="organizers" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.organizers')}</Link>
                <Link to="contest" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.contest')}</Link>
                <Link to="conditions" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.conditions')}</Link>
                <Link to="task" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.task')}</Link>
                <Link to="applications" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.application')}</Link>
                <Link to="pass" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.pass')}</Link>
                <Link to="news" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.news')}</Link>
                <Link to="works" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.works')}</Link>
                <Link to="contacts" spy={true} smooth={true} duration={500} offset={-40} className="menu-content__nav-item" onClick={handleMenuToggle}>{t('Header.contacts')}</Link>
              </nav>
            </div>
          </div>}
    </header>
  );
};

export default Header;
