import earth1 from '../../../assets/work-examples/earth1.jpg'

const earthUnivData = [
  {
    school: 'ФГБОУ ВО «Государственный университет по землеустройству»',
    city: 'Москва',
    country: 'Российская Федерация',
    participants: [
      'Вякина Анастасия Андреевна',
      'Канева Анастасия Максимовна',
      'Лабушкин Виталий Юрьевич',
      'Мухарамова Диана Айратовна',
      'Сурикова Диана Дмитриевна',
      'Поян Анна',
      'Барсуков Николай Сергеевич',
      'Ильвицкая Светлана Валерьевна'
    ],
    images: [earth1]
  },
];

export default earthUnivData;