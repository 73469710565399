import React, { useState } from 'react';
import './Works.scss'
import { Link } from 'react-router-dom';
import satbayevUniversityData from './Universities/1-satbayev-data'
import kazgasaUniversityData from './Universities/2-kazgasa.data'
import rightArrow from '../../assets/right-arrow.png'
import archBureaData from './Universities/3-archburea-data'
import tashkentData from './Universities/4-tashkent-data'
import kyrgyzData from './Universities/5-kyrguz-data'
import kyrgyzRusData from './Universities/6-kyrgyz-rus-data'
import earthUnivData from './Universities/7-earth-univ-data'
import kaspianUnivData from './Universities/8-kaspian-univ-data'
import gumilevUnivData from './Universities/9-gumilev-data'
import karagandaUnivData from './Universities/10-karaganda-data'
import ptamData from './Universities/11-ptam-data'


const Works = () => {

  const handleLinkClick = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
  };


  return (
    <section className='works'>
      <div className='works__container'>
        <h1 className='works__mainTitle'>Работы участников</h1>
        <div className='works__blockContainer'>
          <Link to={{
            pathname: "/example",

          }} state={{ yourProp: satbayevUniversityData}} onClick={handleLinkClick}>
            <div className='works__item' onClick={handleLinkClick}>
              <span className='works__itemTitle'>Казахский национальный исследовательский технический университет имени К. И. Сатпаева</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: kazgasaUniversityData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Международная образовательная корпорация (КазГАСА)</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: archBureaData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Архитектурное бюро «BA concept»</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: tashkentData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Ташкентский архитектурно строительный университет</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: kyrgyzData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Кыргызский Государственный технический университет им. Исхака Раззакова</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: kyrgyzRusData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Кыргызско-Русский Славянский университет имени Б.Н. Ельцина</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: earthUnivData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>ФГБОУ ВО «Государственный университет по землеустройству»</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: kaspianUnivData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Каспийский общественный университет (Caspian University)</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: gumilevUnivData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>Евразийский национальный университет им. Л.Н. Гумилева</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: karagandaUnivData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>НАО «Карагандинский технический университет имени Абылкаса Сагинова»</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
          <Link to="/example" state={{ yourProp: ptamData }} onClick={handleLinkClick}>
            <div className='works__item'>
              <span className='works__itemTitle'>ООО «ПТАМ ВИССАРИОНОВА»</span>
              <div className='works__rightArrow'>
                <img src={rightArrow} alt='' className='arrow'></img>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Works;