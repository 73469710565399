import React from 'react';
import './WorkPageItem.scss'

const WorkPageItem = ({ yourPropValue }) => (
  <div className='work-page__item'>
    <div className='work-page__mainInfoItem'>
      <div className='work-page__infoTitleBlock'>
        <h3 className='work-page__infoTitle'>Школа:</h3>
      </div>
      <span className='work-page__infoValue'>{yourPropValue.school}</span>
    </div>
    <div className='work-page__mainInfoItem'>
      <div className='work-page__infoTitleBlock'>
        <h3 className='work-page__infoTitle'>Город:</h3>
      </div>
      <span className='work-page__infoValue'>{yourPropValue.city}</span>
    </div>
    <div className='work-page__mainInfoItem'>
      <div className='work-page__infoTitleBlock'>
        <h3 className='work-page__infoTitle'>Страна:</h3>
      </div>
      <div className='work-page__infoValue'>
        {yourPropValue.country}
      </div>
    </div>
    <div className='work-page__mainInfoItem'>
      <div className='work-page__infoTitleBlock'>
        <h3 className='work-page__infoTitle'>Участники:</h3>
      </div>
      <div className='work-page__participantsBlock'>
        {yourPropValue.participants.map((participant, index) => (
          <div key={index} className='work-page__participantsItem'>
            {participant}
          </div>
        ))}
      </div>
    </div>
    <div>
      <h3 className='work-page__compTitle'>Конкурсная работа</h3>
      <div className='work-page__imagesBlock'>
        {yourPropValue.images.map((image, index) => (
          <img key={index}
           src={image} 
           alt="" className="work-page__img"/>
        ))}
      </div>
    </div>
  </div>
);

export default WorkPageItem;
