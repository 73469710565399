import React from 'react';
import './News.scss'
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import pricePhoto from '../../assets/pricePhoto.jpg'

const News = () => {

  const {t} = useTranslation();

  return (
    <section className='news-section'>
      <div className='news-section__container'>
        <h1 className='news-section__main-title'>{t('News.title')}</h1>

        <div className='news-section__main-news'>
          <Fade>
            <span className='news-section__date'>{t('News.main_news_date')}</span>

            <span className='news-section__subdesc'>
              {t('News.main_news_subtitle')}
            </span>

            <p className='news-section__mainDesc'>{t('News.main_news1')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news2')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news3')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news4')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news5')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news6')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news7')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news8')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news9')}</p>
            
            <span className='news-section__underlineSubtitle'>{t('News.main_priceTitle')}</span>

            <span className='news-section__cursivePlaceTitle'>
              {t('News.main_firstPlace')}
              <span className='news-section__mainDesc'>{t('News.main_firstPlaceTxt')}</span>
            </span>
            <span className='news-section__cursivePlaceTitle'>
              {t('News.main_secondPlace')}
              <span className='news-section__mainDesc'>{t('News.main_secondPlaceTxt')}</span>
            </span>
            <span className='news-section__cursivePlaceTitle'>
              {t('News.main_thirdPlace')}
              <span className='news-section__mainDesc'>{t('News.main_thirdPlaceTxt')}</span>
            </span>

            <p className='news-section__mainDesc'>{t('News.main_news10')}</p>
            <p className='news-section__mainDesc'>{t('News.main_news11')}</p>

            <img src={pricePhoto} alt='' className='news-section__priceImg'></img>

          </Fade>
        </div>

        <div className='news-section__news-block'>
          <Fade left>
            <div className='news-section__news-item'>
              <span className='news-section__date'>{t('News.date')}</span>
              <span className='news-section__desc'>{t('News.news')}</span>
            </div>
          </Fade>
          <Fade right>
            <div className='news-section__news-item'>
              <span className='news-section__date'>{t('News.date1')}</span>
              <span className='news-section__desc'>{t('News.news1')}&nbsp; 
                 <span className='news-section__descBold'>{t('News.news1_endDate')}</span>
                 <span className='news-section__desc'> {t('News.news1_endKz')}</span>
              </span>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default News;