import React from 'react';
import './Conditions.scss'
import members from '../../assets/members.png'
import like from '../../assets/like.png'
import nomination from '../../assets/nomination.png'
import { Bounce } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Conditions = () => {

  const {t} = useTranslation();

  return (
    <section className="conditions-section">
      <div className='conditions-section__container'>
        <h1 className='conditions-section__main-title'>{t('Conditions.title')}</h1>

        <div className='conditions-section__items-block'>

          <Bounce left duration={2000}>
            <div className='conditions-section__cond-item'>
              <h2 className='conditions-section__cond-title'>{t('Conditions.members')}</h2>
              <div className='conditions-section__desc-box'>
                <div className='conditions-section__logo-block'>
                  <img className='conditions-section__logo' src={members} alt=''></img>
                </div>
                <div className='conditions-section__desc-txt'>
                  <span className='conditions-section__txt'>{t('Conditions.membersText')}</span>
                  <ul className='conditions-section__list-block'>
                    <li>{t('Conditions.membersListOne')}</li>
                    <li>{t('Conditions.membersListTwo')}</li>
                    <li>{t('Conditions.membersListThree')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </Bounce>

          <Bounce right duration={2000}>
            <div className='conditions-section__cond-item'>
              <h2 className='conditions-section__cond-title'>{t('Conditions.jury')}</h2>
              <div className='conditions-section__desc-box'>
                <div className='conditions-section__logo-block'>
                  <img className='conditions-section__logo' src={like} alt=''></img>
                </div>
                <div className='conditions-section__desc-txt'>
                  <span className='conditions-section__txt'>{t('Conditions.juryText')}</span>
                  <span className='conditions-section__txt'>{t('Conditions.juryTextTwo')}</span>
                  <ul className='conditions-section__list-block'>
                    <li>{t('Conditions.juryListOne')}</li>
                    <li>{t('Conditions.juryListTwo')}</li>
                    <li>{t('Conditions.juryListThree')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </Bounce>

          <Bounce left duration={2000}>
            <div className='conditions-section__cond-item'>
              <h2 className='conditions-section__cond-title'>{t('Conditions.nominations')}</h2>
              <div className='conditions-section__desc-box'>
                <div className='conditions-section__logo-block'>
                  <img className='conditions-section__logo' src={nomination} alt=''></img>
                </div>
                <div className='conditions-section__desc-txt'>
                  <span className='conditions-section__txt'>{t('Conditions.nominationsText')}</span>
                  <ul className='conditions-section__list-block--numerical'>
                    <li><span className='conditions-section__romanian'>{t('Conditions.nominationFirstPlace')}</span> 
                      {t('Conditions.nominationFirstPrize')}
                    </li>
                    <li><span className='conditions-section__romanian'>{t('Conditions.nominationSecondPlace')}</span> 
                      {t('Conditions.nominationSecondPrize')}
                    </li>
                    <li><span className='conditions-section__romanian'>{t('Conditions.nominationThirdPlace')}</span> 
                      {t('Conditions.nominationThirdPrize')}
                    </li>
                  </ul>
                  <span className='conditions-section__txt'>{t('Conditions.nominationDescOne')}</span>
                  <span className='conditions-section__txt'>{t('Conditions.nominationDescTwo')}</span>
                </div>
              </div>
            </div>
          </Bounce>

        </div>

         

      </div>
    </section>
  );
};

export default Conditions;