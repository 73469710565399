import React from 'react';
import './Task.scss'
import { Fade } from 'react-reveal';
import Jump from 'react-reveal/Jump';
import { useTranslation } from 'react-i18next';

const Task = () => {

  const {t} = useTranslation();


  return (
    <section className="task-section">
      <div className='task-section__container'>
        <Jump>
          <h1 className='task-section__main-title'>{t('Task.title')}</h1>
        </Jump>

        <div className='task-section__target-box'>
          <span className='task-section__main-target'>
            <span className='task-section__main-target--bold'>{t('Task.mainTarget')} </span>{t('Task.target')}
            <span className='task-section__main-target--cursive'> {t('Task.coursiveTarget')}</span>
          </span>
        </div>

        <h3 className='task-section__second-title'>{t('Task.secondTitle')}</h3>

        <div className='task-section__task-block'>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>1</div>
              <span className='task-section__task-txt'>{t('Task.firstItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>2</div>
              <div className='task-section__func-zone'>
                <span className='task-section__task-txt'>{t('Task.secondItem')}</span>
                <ul className='task-section__list'>
                    <li>{t('Task.secondListOne')}</li>
                    <li>{t('Task.secondListTwo')}</li>
                    <li>{t('Task.secondListThree')}</li>
                    <li>{t('Task.secondListFour')}</li>
                    <li>{t('Task.secondListFive')}</li>
                    <li>{t('Task.secondListSix')}</li>
                    <li>{t('Task.secondListSeven')}</li>
                </ul>
                <span className='task-section__task-txt'>{t('Task.secondConclusion')}</span>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>3</div>
              <span className='task-section__task-txt'>{t('Task.thirdItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>4</div>
              <span className='task-section__task-txt'>{t('Task.fourthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>5</div>
              <span className='task-section__task-txt'>{t('Task.fifthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>6</div>
              <span className='task-section__task-txt'>{t('Task.sixthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>7</div>
              <span className='task-section__task-txt'>{t('Task.seventhItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>8</div>
              <span className='task-section__task-txt'>{t('Task.eighthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>9</div>
              <span className='task-section__task-txt'>{t('Task.ninthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>10</div>
              <span className='task-section__task-txt'>{t('Task.tenthItem')}</span>
            </div>
          </Fade>
          <Fade bottom>
            <div className='task-section__task-item'>
              <div className='task-section__circle'>11</div>
              <span className='task-section__task-txt'>{t('Task.eleventhItem')}</span>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Task;