import React, { useState, useEffect } from 'react';
import './LanguageChanger.scss'
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const LanguageChanger = () => {

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const {t, i18n} = useTranslation();
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'ru';

  const locales = {
    kz: { title: 'KZ' },
    ru: { title: 'RU' },
    en: { title: 'EN' },
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const changeLanguageAndToggleDropdown = (locale) => {
    i18n.changeLanguage(locale);
    setDropdownOpen(!isDropdownOpen);
    localStorage.setItem('selectedLanguage', locale);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <>
      <div className='language-block' onClick={toggleDropdown}>
        {t('language.title')}
        <span className='language-block__arrow'>▼</span>
        {isDropdownOpen && (
        <Fade duration={800}>
          <div className="dropdown-menu">
            {Object.keys(locales).map((locale) => (
              <button
                key={locale}
                style={{
                  fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal',
                  color: i18n.resolvedLanguage === locale ? 'rgb(255, 192, 0)' : 'black'
                }}
                type="submit"
                onClick={() => changeLanguageAndToggleDropdown(locale)}
                className='dropdown-menu__item'
              >
                {locales[locale].title}
              </button>
            ))}
            
          </div>
        </Fade>
      )}
      </div>
    </>
  );
};

export default LanguageChanger;
