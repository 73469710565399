import React, { useEffect } from 'react';
import './WorkPage.scss'
import { useLocation } from 'react-router-dom';
import WorkHeader from '../WorkHeader/WorkHeader';
import Footer from '../Footer/Footer';
import WorkPageItem from './WorkPageItem/WorkPageItem';
import ScrollButton from '../../shared/ScrollButton/ScrollButton'

const WorkPage = () => {
  const location = useLocation();
  const universitiesData = location.state?.yourProp;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WorkHeader/>
      <main className='work-page'>
        <div className='work-page__container'>
          <h1 className='work-page__mainTitle'>Конкурсные работы</h1>

          <div className='work-page__itemsBlock'>
            {universitiesData.map((university, index) => (
              <WorkPageItem key={index} yourPropValue={university} />
            ))}
          </div>
        </div>
        <ScrollButton />
      </main>
      <Footer/>
    </>
  );
};

export default WorkPage;