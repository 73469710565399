import React from 'react';
import './MaamDescription.scss'
import { useTranslation } from 'react-i18next';

const MaamDescription = () => {

  const { t } = useTranslation();

  return (
    <div className='maam'>
      <span className='maam__txt'>
        <span className='maam__txt-bold'>{t('Organizers.maamTitle')}</span>
          {t('Organizers.maamDesc')}
      </span>
      <span className='maam__txt'>{t('Organizers.maamSubtitle')}</span>
      <ul className='maam__list'>
        <li>{t('Organizers.maamList1')}</li>
        <li>{t('Organizers.maamList2')}</li>
      </ul>
      
    </div>
  );
};

export default MaamDescription;