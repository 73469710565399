import React from 'react';
import './Contest.scss'
import contest1 from '../../assets/contest1.jpeg'
import contest2 from '../../assets/contest2.jpg'
import contest3 from '../../assets/contest3.jpeg'
import contest4 from '../../assets/contest4.jpg'
import { Bounce, Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Contest = () => {

  const {t} = useTranslation();


  return (
    <section className="contest-section">
      <div className='contest-section__container'>
        <h1 className='contest-section__main-title'>{t('Contest.title')}</h1>

        <div className='contest-section__condition-block'>
          <span className='contest-section__target-txt'>
            <span className='contest-section__target-title'>{t('Contest.targetTitle')} </span>
            {t('Contest.target')}</span>
        </div>

        <div className='contest-section__card-block'>
          <div className='contest-section__card-item'>
            <Bounce left>
              <div className='contest-section__card-img'>
                <img src={contest1} alt='' className='contest-section__contest-img'/>
              </div>
            </Bounce>
            <Fade right>
              <div className='contest-section__card-description'>
                <span className='contest-section__card-txt'>
                  {t('Contest.cardOne')}
                </span>
              </div>
            </Fade>
          </div>

          <div className='contest-section__card-item--left'>
            <Fade left>
              <div className='contest-section__card-description--left'>
                <span className='contest-section__card-txt'>
                  {t('Contest.cardTwo')}
                </span>
              </div>
            </Fade>
            <Bounce right>
              <div className='contest-section__card-img--left'>
                <img src={contest2} alt='' className='contest-section__contest-img'/>
              </div>
            </Bounce>
          </div>

          <div className='contest-section__card-item'>
            <Bounce left>
              <div className='contest-section__card-img'>
                <img src={contest3} alt='' className='contest-section__contest-img'/>
              </div>
            </Bounce>
            <Fade right>
              <div className='contest-section__card-description'>
                <span className='contest-section__card-txt'>
                  {t('Contest.cardThree')} 
                </span>
              </div>
            </Fade>
          </div>

          <div className='contest-section__card-item--left'>
            <Fade left>
              <div className='contest-section__card-description--left'>
                <span className='contest-section__card-txt'>
                  {t('Contest.cardFour')}
                </span>
              </div>
            </Fade>
            <Bounce right>
              <div className='contest-section__card-img--left'>
                <img src={contest4} alt='' className='contest-section__contest-img'/>
              </div>
            </Bounce>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Contest;