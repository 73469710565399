import React from 'react';
import './Modal.scss'
import NomadDescription from '../ui/NomadDescription/NomadDescription';
import MaamDescription from '../ui/MaamDescription/MaamDescription';
import SatbayevDescription from '../ui/SUDescription/SatbayevDescription';

const Modal = ({ isOpen, onClose, logo, description }) => {

  if (!isOpen) {
    return null;
  }

  const descriptionComponents = {
    nomad: <NomadDescription />,
    iamm: <MaamDescription />,
    satbayev: <SatbayevDescription/>,
  };

  const selectedDescriptionComponent = descriptionComponents[description];

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className='modal__container'>
          <button className="close-button" onClick={onClose}>&#x2715;</button>
          <div className='modal__logo-block'>
            <img src={logo} alt="" className='modal__logo'/>
          </div>
          <div className='modal__txt-block'>
            {selectedDescriptionComponent}
     
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Modal;
