import kaspian1 from '../../../assets/work-examples/kaspian1.png'
import kaspian2 from '../../../assets/work-examples/kaspian2.jpg'

const kaspianUnivData = [
  {
    school: 'Каспийский общественный университет (Caspian University)',
    city: 'Алматы',
    country: 'Республика Казахстан',
    participants: [
      'Дюсекенева Карина Маратовна',
      'Колесникова Ангелина Денисовна',
      'Яхияева Сафина Алишеровна',
      'Муталиев Алишер Дильмуратович'
    ],
    images: [kaspian1]
  },
  {
    school: 'Каспийский общественный университет (Caspian University)',
    city: 'Алматы',
    country: 'Республика Казахстан',
    participants: [
      'Исабекова Динара',
      'Никулина Анастасия Владимировна',
      'Физикова Дарья',
      'Чудненко Александра Андреевна',
      'Муталиев Алишер Дильмуратович'
    ],
    images: [kaspian2]
  },
];

export default kaspianUnivData;