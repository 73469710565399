import React from 'react';
import './NomadDescription.scss'
import { useTranslation } from 'react-i18next';

const NomadDescription = () => {

  const { t } = useTranslation(); // Destructure i18n from useTranslation
  
  return (
    <div className='nomad'>
      <span className='nomad__txt'>
        <span className='nomad__txt-bold'>{t('Organizers.nomadTitle')}</span>
          {t('Organizers.nomadDesc')}
      </span>
    </div>
  );
};

export default NomadDescription;